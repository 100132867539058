<template>
  <div class="warehouse-detail-parcels">
    <quick-message
      class="mb-2 mt-4"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="typeMessage"
    />
    <div class="d-flex justify-content-between align-items-center">
      <div class="mt-4 d-flex flex-column flex-lg-row justify-content-lg-start">
        <div class="mt-lg-0 mr-lg-2">
          <z-search-input v-model.trim="searchText" placeholder="Buscar ID" />
        </div>
        <div class="mt-2 mt-lg-0 mr-lg-2">
          <date-picker
            v-model="selectedFromDate"
            calendar-class="date-picker"
            :language="customDate.language"
            :disabled-dates="disableDatesFrom"
            input-class="form-control bg-white w-100"
            placeholder="Desde"
          />
        </div>
        <div class="mt-2 mt-lg-0 mr-lg-2">
          <date-picker
            v-model="selectedUntilDate"
            calendar-class="date-picker"
            :language="customDate.language"
            :disabled-dates="disableDatesUntil"
            input-class="form-control bg-white w-100"
            placeholder="Hasta"
          />
        </div>
        <div class="mt-2 mt-lg-0 mr-lg-2">
          <z-dropdown
            v-model="selectedParcelStatus"
            :options="parcelStatusOptions"
            max-height="230px"
            name="Estado"
          />
        </div>
        <div class="mt-2 mt-lg-0 mr-lg-2">
          <z-dropdown
            v-model="selectedParcelDeliveryMode"
            :options="parcelDeliveryModeOptions"
            max-height="230px"
            name="Modalidad de entrega"
          />
        </div>
        <div class="mt-2 mt-lg-0">
          <z-dropdown-check-list-clients v-model="selectedParcelClient" />
        </div>
      </div>
      <div>
        <z-button-refresh :loading="loading" @refresh="getListing" />
      </div>
    </div>
    <z-table
      class="mt-4"
      hover
      responsive="lg"
      :items="parcels"
      :fields="fields"
      :busy="loading"
      style="min-height:330px"
      @row-clicked="handleRowClick"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <loading-spinner />
        </div>
      </template>
      <template v-slot:empty>
        <p class="text-center py-5 my-5 font-weight-semi-bold">
          No hay Guías registradas con este filtro
        </p>
      </template>
      <!-- Start: Headers -->
      <template v-slot:head(deliveryDate)>
        <z-table-head-sort :order.sync="dateOrder" class="header-date">
          Fecha de entrega
        </z-table-head-sort>
      </template>
      <!-- End: Headers -->
      <!-- Start: Cells -->
      <template v-slot:cell(id)="data">
        <z-copy-id tooltip-placement="right" :text="data.value" />
      </template>
      <template #cell(client-destinationName)="data">
        {{ data.item.client }} - {{ data.item.destinationName }}
      </template>
      <template v-slot:cell(status)="data">
        <div>
          <status-parcel :status="data.value" />
        </div>
      </template>
      <template v-slot:cell(deliveryDate)="data">
        {{ data.value }}
      </template>
      <template v-slot:cell(actions)="data">
        <div class="d-flex align-items-center justify-content-center">
          <z-tracking-link :id="data.item.id" is-parcel shorten-url />
        </div>
      </template>
      <template v-slot:cell(cancel)="data">
        <div class="d-flex align-items-center ">
          <z-button
            v-if="disableCancel(data.item.status)"
            variant="link"
            @click="showModal(data.item.id, data.index)"
          >
            Cancelar Paquete
          </z-button>
        </div>
      </template>
      <template #cell(options)="row">
        <div class="options-container">
          <b-dropdown
            menu-class="transactions-dropdown"
            variant="link"
            size="md"
            no-caret
            dropleft
            lazy
            boundary="window"
            :disabled="
              !disableCancel(row.item.status) &&
                !showScan(row.item.status) &&
                !showLost(row.item.status)
            "
          >
            <template
              v-if="
                disableCancel(row.item.status) ||
                  showScan(row.item.status) ||
                  showLost(row.item.status) ||
                  showReturnToOrigin(row.item.status)
              "
              slot="button-content"
            >
              <font-awesome-icon icon="ellipsis-h" />
            </template>

            <b-dropdown-item
              v-if="showScan(row.item.status)"
              @click="scanParcel(row.item.id, row.index)"
            >
              Registrar en bodega
            </b-dropdown-item>
            <b-dropdown-item
              v-if="showLost(row.item.status)"
              @click="markAsLost(row.item.id, row.index)"
            >
              Marcar paquete como perdido
            </b-dropdown-item>
            <b-dropdown-item
              v-if="showReturnToOrigin(row.item.status)"
              @click="setReturnedToOrigin(row.item.id, row.index)"
            >
              Marcar como Regresado al origen
            </b-dropdown-item>
            <b-dropdown-item
              v-if="disableCancel(row.item.status)"
              @click="showModal(row.item.id, row.index)"
            >
              Cancelar paquete
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <!-- End: Cells -->
    </z-table>
    <z-table-pagination
      :total-rows="totalRows"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
    <confirmation-dialog
      :show="showConfirmationModal"
      @accept="handleCancel()"
      @cancel="hideModal()"
      @hidden="hideModal()"
    >
      ¿Seguro que deseas cancelar el Paquete
      <b>{{ shortId(cancelId) }}</b> ?
    </confirmation-dialog>
  </div>
</template>

<script>
import StatusParcel from "@zubut/common/src/components/StatusParcel";
import ZButtonRefresh from "@zubut/common/src/components/ZButtonRefresh.vue";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZDropdownCheckListClients from "@/app/components/ZDropdownCheckListClients.vue";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTable from "@zubut/common/src/components/ZTable";
import ZTableHeadSort from "@zubut/common/src/components/ZTableHeadSort";
import ZTrackingLink from "@zubut/common/src/components/ZTrackingLink";
import ZCopyId from "@zubut/common/src/components/ZCopyId";
import ParcelStatus from "@/constants/parcels/status";
import ParcelDeliveryMode from "@/constants/parcels/deliveryMode";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import ScreensMixin from "@/mixins/screen";
import * as mutation from "@/store/modules/serviceCenter/warehouses/mutations-types";
import Parcels from "@/services/parcels";
import Warehouses from "@/services/warehouses";
import { deflateUUID, makeShortUUID } from "@zubut/common/src/utils/strings";
import { format } from "@zubut/common/src/utils/time";
import DatePicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import _debounce from "lodash/debounce";

const moduleName = "serviceCenter/warehouses";

export default {
  name: "WarehouseDetailParcels",

  components: {
    DatePicker,
    StatusParcel,
    ZButtonRefresh,
    ZDropdown,
    ZDropdownCheckListClients,
    ZSearchInput,
    ZTable,
    ZTableHeadSort,
    ZTablePagination,
    ZTrackingLink,
    ZCopyId,
    ConfirmationDialog
  },

  mixins: [ScreensMixin],

  props: {
    cityId: {
      type: String,
      default: ""
    },
    updateListing: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: true,
      customDate: {
        language: es
      },
      fields: [
        {
          key: "id",
          label: "ID",
          thStyle: { "min-width": "100px" }
        },
        {
          key: "client-destinationName",
          label: "Cliente - Nombre de destino"
        },
        { key: "clusterZone", label: "Zona" },
        { key: "warehouseName", label: "Bodega" },
        { key: "status", label: "Estatus" },
        {
          key: "deliveryDate",
          label: "Fecha de entrega",
          formatter: "deliveryFormatter"
        },
        { key: "actions", label: "Compartir" },
        { key: "options", label: "Acciones" }
      ],
      parcelStatusOptions: [{ text: "Todos", value: null }].concat(
        ParcelStatus.options
      ),
      parcelDeliveryModeOptions: [{ text: "Todos", value: null }].concat(
        ParcelDeliveryMode.options
      ),
      showParcelDetail: false,
      showConfirmationModal: false,
      selectedParcel: null,
      message: "",
      typeMessage: "success",
      hasError: false,
      hasMessage: false,
      cancelId: null,
      cancelIndex: null
    };
  },

  computed: {
    perPage: {
      get() {
        return this.$store.getters[`${moduleName}/getParcelsPaginationPerPage`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_PAGINATION_PER_PAGE}`,
          val
        );
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[
          `${moduleName}/getParcelsPaginationCurrentPage`
        ];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_PAGINATION_CURRENT_PAGE}`,
          val
        );
      }
    },
    dateOrder: {
      get() {
        return this.$store.getters[`${moduleName}/getParcelsFilterDateOrder`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_FILTER_DATE_ORDER}`,
          val
        );
      }
    },
    totalRows() {
      return this.$store.getters[`${moduleName}/getParcelsTotal`];
    },
    skip() {
      return this.perPage * (this.currentPage - 1);
    },
    filter() {
      return { skip: this.skip, limit: this.perPage };
    },
    parcels() {
      return this.$store.getters[`${moduleName}/getParcels`];
    },
    searchText: {
      get() {
        return this.$store.getters[`${moduleName}/getParcelsFilterText`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_FILTER_TEXT}`,
          val
        );
      }
    },
    selectedParcelStatus: {
      get() {
        return this.$store.getters[`${moduleName}/getParcelsFilterStatus`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_FILTER_STATUS}`,
          val
        );
      }
    },
    selectedParcelDeliveryMode: {
      get() {
        return this.$store.getters[
          `${moduleName}/getParcelsFilterDeliveryMode`
        ];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_FILTER_DELIVERY_MODE}`,
          val
        );
      }
    },
    selectedParcelClient: {
      get() {
        return this.$store.getters[`${moduleName}/getParcelsFilterClientId`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_FILTER_CLIENT}`,
          val
        );
      }
    },
    selectedFromDate: {
      get() {
        return this.$store.getters[`${moduleName}/getParcelsFilterFromDate`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_FILTER_FROM}`,
          val
        );
      }
    },
    selectedUntilDate: {
      get() {
        return this.$store.getters[`${moduleName}/getParcelsFilterUntilDate`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_PARCELS_FILTER_UNTIL}`,
          val
        );
      }
    },
    disableDatesFrom() {
      if (this.selectedUntilDate == null) {
        return {};
      }
      return { from: new Date(this.selectedUntilDate) };
    },

    disableDatesUntil() {
      if (this.selectedFromDate == null) {
        return {};
      }
      return { until: new Date(this.selectedFromDate) };
    }
  },

  watch: {
    searchText() {
      this.search();
    },

    updateListing: {
      handler(newVal) {
        if (newVal) {
          this.getListing();
          this.$emit("update:updateListing", false);
        }
      }
    },

    dateOrder: "getListing",
    currentPage: "getListing",
    perPage: "getParcelsAndResetPagination",
    selectedParcelStatus: "getParcelsAndResetPagination",
    selectedParcelDeliveryMode: "getParcelsAndResetPagination",
    selectedParcelClient: "getParcelsAndResetPagination",
    selectedUntilDate: "getParcelsAndResetPagination",
    selectedFromDate: "getParcelsAndResetPagination"
  },

  created() {
    this.$store.commit(`${moduleName}/${mutation.CLEAR_PARCELS_FILTERS}`);
    this.getListing();
    this.search = _debounce(() => {
      this.getListing();
    }, 600);
  },

  methods: {
    deliveryFormatter(value, key, item) {
      const deliveryModeFormatted = ParcelDeliveryMode.get[item.deliveryMode];
      const deliveryDateFormatted = format(
        new Date(item.deliveryDate),
        "dd 'de' MMMM, yyyy"
      );

      return `${deliveryModeFormatted}: ${deliveryDateFormatted}`;
    },
    getListing() {
      this.loading = true;
      const { where, filter } = this.buildRequest();
      this.$store
        .dispatch(`${moduleName}/getParcelsListing`, {
          where,
          filter
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildRequest() {
      const filter = { ...this.filter };
      const where = { cityId: this.cityId };
      if (this.searchText) where.id = { like: `%${this.searchText}%` };

      if (this.selectedParcelStatus != null)
        where.status = this.selectedParcelStatus;

      if (this.selectedParcelDeliveryMode != null)
        where.deliveryMode = this.selectedParcelDeliveryMode;

      if (this.selectedParcelClient.length > 0)
        where.clientId = { inq: this.selectedParcelClient };

      if (this.selectedFromDate != null && this.selectedUntilDate != null) {
        where.range = {
          from: new Date(this.selectedFromDate),
          until: new Date(this.selectedUntilDate)
        };
      } else if (this.selectedFromDate != null) {
        where.deliveryDate = { gte: new Date(this.selectedFromDate) };
      } else if (this.selectedUntilDate != null) {
        where.deliveryDate = { lte: new Date(this.selectedUntilDate) };
      }

      return { where, filter };
    },
    shortId(val) {
      return makeShortUUID(val);
    },
    getParcelsAndResetPagination() {
      this.paginationReset();
      this.getListing();
    },

    paginationReset() {
      this.currentPage = 1;
    },

    handleRowClick(item) {
      // Show modal on desktop & route to page on mobile
      if (this.isDesktop()) {
        this.showParcelDetail = true;
        this.$emit("selected-parcel", item.id);
      } else {
        this.$router.push({ name: "parcelDetail", params: { id: item.id } });
      }
    },
    disableCancel(val) {
      return (
        val === ParcelStatus.NUM_PENDING_COLLECTION ||
        val === ParcelStatus.NUM_IN_WAREHOUSE
      );
    },
    showScan(val) {
      return (
        [
          ParcelStatus.NUM_PENDING_COLLECTION,
          ParcelStatus.NUM_COLLECTED,
          ParcelStatus.NUM_DELIVERY_ATTEMPT
        ].indexOf(val) > -1
      );
    },
    showLost(val) {
      return val !== ParcelStatus.NUM_LOST;
    },
    showReturnToOrigin(val) {
      return (
        val === ParcelStatus.NUM_IN_WAREHOUSE ||
        val === ParcelStatus.NUM_DELIVERY_ATTEMPT
      );
    },
    showModal(id, index) {
      this.cancelId = id;
      this.cancelIndex = index;
      this.showConfirmationModal = true;
    },

    handleCancel() {
      Parcels.cancel(this.cancelId)
        .then(() => {
          this.message = "Paquete cancelado exitosamente";
          this.typeMessage = "success";
          this.hasMessage = true;
          this.$store.commit(`${moduleName}/${mutation.UPDATE_PARCEL_STATUS}`, {
            val: ParcelStatus.NUM_CANCELLED,
            index: this.cancelIndex
          });

          this.hideModal();
        })
        .catch(error => {
          this.$captureError(error);
          this.message = error.message;
          this.typeMessage = "error";
          this.hasMessage = true;
        });
    },

    hideModal() {
      this.showConfirmationModal = false;
      this.cancelId = null;
      this.cancelIndex = null;
    },

    scanParcel(parcelId, index) {
      const warehouseId = this.parcels[index].warehouseId;
      Warehouses.scanParcel(warehouseId, {
        safeParcelId: deflateUUID(parcelId)
      })
        .then(res => {
          this.message = "Paquete escaneado exitosamente";
          this.typeMessage = "success";
          this.hasMessage = true;
          this.$store.commit(`${moduleName}/${mutation.UPDATE_PARCEL_STATUS}`, {
            val: res.status,
            index: index
          });
        })
        .catch(error => {
          this.$captureError(error);
          this.message = error.message;
          this.typeMessage = "error";
          this.hasMessage = true;
        });
    },

    markAsLost(parcelId, index) {
      Parcels.markAsLost(parcelId)
        .then(() => {
          this.message = "Marcado como perdido exitosamente";
          this.typeMessage = "success";
          this.hasMessage = true;
          this.$store.commit(`${moduleName}/${mutation.UPDATE_PARCEL_STATUS}`, {
            val: ParcelStatus.NUM_LOST,
            index: index
          });
        })
        .catch(error => {
          this.$captureError(error);
          this.message = error.message;
          this.typeMessage = "error";
          this.hasMessage = true;
        });
    },
    setReturnedToOrigin(parcelId, index) {
      Parcels.setReturnedToOrigin(parcelId)
        .then(() => {
          this.message = "Marcado como Regresado al origen exitosamente";
          this.typeMessage = "success";
          this.hasMessage = true;
          this.$store.commit(`${moduleName}/${mutation.UPDATE_PARCEL_STATUS}`, {
            val: ParcelStatus.NUM_RETURNED_TO_ORIGIN,
            index: index
          });
        })
        .catch(error => {
          this.$captureError(error);
          this.message = error.message;
          this.typeMessage = "error";
          this.hasMessage = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.header-address {
  min-width: 180px;
}
.refresh {
  display: flex;
  justify-content: center;
}
@media (min-width: 860px) {
  .refresh {
    justify-content: flex-end;
  }
}
</style>
