<template>
  <div class="warehouse-detail">
    <z-responsive-modal
      :show.sync="showModal"
      size="xl"
      :hide-close="showParcelDetail"
      :body-class="
        `warehouse-detail-modal-body ${showParcelDetail ? 'behind' : ''}`
      "
      @hide="handleClose"
    >
      <warehouse-city-header :city-id="cityId" />
      <b-tabs v-show="!showParcelDetail" class="mt-3" content-class="mt-3">
        <b-tab title="Paquetes">
          <warehouse-detail-parcels
            :city-id="cityId"
            :update-listing.sync="update"
            @selected-parcel="handleSelectParcel"
          />
          <parcel-detail
            :show.sync="showParcelDetail"
            :parcel-id="selectedParcel"
          />
        </b-tab>
        <b-tab title="Recolección" lazy>
          <warehouses-list
            is-only-zubut-warehouses
            @update-listing="updateListing"
          />
        </b-tab>
        <b-tab title="Entrega" lazy>
          <warehouses-list @update-listing="updateListing" />
        </b-tab>
      </b-tabs>
    </z-responsive-modal>
  </div>
</template>

<script>
import WarehouseCityHeader from "./WarehouseCityHeader";
import WarehouseDetailParcels from "./WarehouseDetailParcels";
import WarehousesList from "./WarehousesList";
import ZResponsiveModal from "@zubut/common/src/components/ZResponsiveModal";
import ParcelDetail from "@/app/views/ParcelDetail/ParcelDetailDesktop";

export default {
  name: "WarehouseDetail",

  components: {
    ParcelDetail,
    WarehouseCityHeader,
    WarehousesList,
    WarehouseDetailParcels,
    ZResponsiveModal
  },

  data() {
    return {
      showModal: true,
      showParcelDetail: false,
      selectedParcel: "",
      update: false
    };
  },

  computed: {
    cityId() {
      return this.$route.params.id;
    }
  },

  methods: {
    handleClose() {
      this.$router.go(-1);
      this.showModal = false;
    },
    handleSelectParcel(parcelId) {
      this.showParcelDetail = true;
      this.selectedParcel = parcelId;
    },
    updateListing() {
      this.update = true;
    }
  }
};
</script>

<style lang="scss">
.warehouse-detail-modal-body {
  min-height: 94vh;
  padding: 1.5rem 1.5rem 3rem 1.5rem !important;
  transition: background-color 300ms ease;

  &.behind {
    background-color: $ghost-white;
  }
}

.tab-content {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
