<template>
  <div>
    <quick-message
      class="mb-2"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="typeOfMessage"
    />
    <div class="mt-4 d-flex flex-column flex-lg-row justify-content-lg-between">
      <div class="d-flex flex-column flex-lg-row justify-content-lg-start">
        <div class="mt-lg-0 mr-lg-2">
          <z-search-input v-model.trim="searchText" placeholder="Buscar ID" />
        </div>
        <div class="mt-2 mt-lg-0 mr-lg-2">
          <z-dropdown
            v-model="selectedCollectionStatus"
            :options="serviceStatusOptions"
            max-height="230px"
            name="Estado"
          />
        </div>
      </div>
      <div>
        <div class="d-flex">
          <z-button
            expanded
            class="mt-3 mt-sm-0 mr-3 create-button"
            @click="showAddCollectionModal = true"
          >
            Generar Servicio
          </z-button>
          <z-button
            expanded
            class="mt-3 mt-sm-0 create-button"
            @click="showCreateCollection = true"
          >
            Generar Clusters
          </z-button>
        </div>
      </div>
    </div>
    <z-table
      class="mt-4"
      hover
      :items="collections"
      :fields="fields"
      :busy="loading"
      @row-clicked="handleRowClick"
    >
      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <loading-spinner />
        </div>
      </template>
      <template v-slot:empty>
        <p class="text-center py-5 my-5 font-weight-semi-bold">
          No hay Servicios de recolección con este filtro
        </p>
      </template>
      <!-- Start: Headers -->
      <template v-slot:head(createdAt)>
        <z-table-head-sort :order.sync="dateOrder" class="header-date">
          Creación
        </z-table-head-sort>
      </template>
      <template v-slot:head(vehicleType)>
        <z-table-head-sort
          :order.sync="vehicleTypeOrder"
          class="header-vehicle"
        >
        </z-table-head-sort>
      </template>
      <template v-slot:head(status)>
        <z-table-head-sort :order.sync="statusOrder" class="header-status">
          Estatus
        </z-table-head-sort>
      </template>
      <!-- End: Headers -->
      <!-- Start: Cells -->
      <template v-slot:cell(id)="data">
        <z-copy-id tooltip-placement="right" :text="data.value" />
      </template>
      <template v-slot:cell(driverName)="row">
        <assign-service-dropdown
          v-if="!statusCheck(row.item.status)"
          :service-id="row.item.id"
          :current-driver="row.item.driverId"
          :current-driver-name="row.item.driverName"
          module-name="serviceCenter/warehouses"
          @success="handleAssignation"
          @error="handleError"
        />
        <div v-else>{{ row.item.driverName }}</div>
      </template>
      <template v-slot:cell(vehicleType)="data">
        <span>
          <font-awesome-icon
            class="vehicle"
            :icon="vehicle(data.value)"
            size="2x"
          />
        </span>
      </template>
      <template v-slot:cell(status)="data">
        <div>
          <status-service :status="data.value" />
        </div>
      </template>
      <template v-slot:cell(actions)="row">
        <b-dropdown
          v-show="!statusCheck(row.item.status)"
          menu-class="transactions-dropdown"
          variant="link"
          size="md"
          no-caret
          dropleft
          lazy
          boundary="window"
        >
          <template slot="button-content">
            <font-awesome-icon icon="ellipsis-h" />
          </template>

          <b-dropdown-item @click="finalizeService(row.item)">
            Finalizar servicio
          </b-dropdown-item>

          <b-dropdown-item @click="cancelService(row.item)">
            Cancelar servicio
          </b-dropdown-item>
        </b-dropdown>
        <!-- <z-button variant="link" @click="cancelService(row.item)">
          Cancelar
        </z-button> -->
      </template>
      <!-- End: Cells -->
    </z-table>
    <z-table-pagination
      :total-rows="totalRows"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
    <confirmation-dialog
      :show.sync="showConfirmationModal"
      close-on-cancel
      @accept="dispatchCancel()"
    >
      ¿Seguro que deseas cancelar el servicio
      <b>{{ selectedServiceId }}</b> ?
    </confirmation-dialog>
    <confirmation-dialog
      :show.sync="showFinalizeConfirmationModal"
      close-on-cancel
      @accept="dispatchFinalize()"
    >
      ¿Seguro que deseas finalizar el servicio
      <b>{{ selectedServiceId }}</b> ?
    </confirmation-dialog>
    <create-clusters
      :warehouse-id="warehouseId"
      :show.sync="showCreateCollection"
      is-collection
      @created-service="getCollectionsAndResetPagination"
      @update-listing="updateListing"
    />
    <create-collection-service
      :warehouse-id="warehouseId"
      :show.sync="showAddCollectionModal"
      @created-service="getCollectionsAndResetPagination"
      @update:show="updateListing"
    />
  </div>
</template>

<script>
import CreateCollectionService from "./CreateCollectionServiceModal";
import CreateClusters from "./CreateClustersModal";
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import StatusService from "@zubut/common/src/components/StatusService";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTable from "@zubut/common/src/components/ZTable";
import ZTableHeadSort from "@zubut/common/src/components/ZTableHeadSort";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ZCopyId from "@zubut/common/src/components/ZCopyId";
import AssignServiceDropdown from "@/app/components/AssignServiceDropdown/AssignServiceDropdown";
import ServiceStatus from "@/constants/services/status";
import DriverVehicles from "@/constants/drivers/vehicles";
import VehicleType from "@/constants/vehicles/type";
import * as mutation from "@/store/modules/serviceCenter/warehouses/mutations-types";
import { makeShortUUID } from "@/utils/strings";
import { formatISO } from "@zubut/common/src/utils/time";
import _debounce from "lodash/debounce";

const moduleName = "serviceCenter/warehouses";

export default {
  name: "WarehouseDetailCollections",

  components: {
    AssignServiceDropdown,
    ConfirmationDialog,
    CreateCollectionService,
    CreateClusters,
    StatusService,
    ZDropdown,
    ZSearchInput,
    ZTable,
    ZTableHeadSort,
    ZTablePagination,
    ZCopyId
  },

  props: {
    warehouseId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      loading: true,
      message: "",
      typeOfMessage: "info",
      isSuccessful: false,
      hasError: false,
      showConfirmationModal: false,
      showFinalizeConfirmationModal: false,
      showAddCollectionModal: false,
      showCreateCollection: false,
      selectedService: null,
      vehicleTypeOrder: "asc",
      statusOrder: "asc",
      fields: [
        {
          key: "id",
          label: "ID",
          thStyle: { "min-width": "100px" }
        },
        {
          key: "createdAt",
          label: "Creación",
          formatter: value => formatISO(value, "dd 'de' MMM yyyy p aaa")
        },
        { key: "driverName", label: "Mensajero" },
        {
          key: "vehicleType",
          label: "Vehículo",
          class: "text-center"
        },
        {
          key: "collectedNo",
          label: "Recolectados",
          class: "text-center",
          formatter: value => (value ? value : 0)
        },
        {
          key: "notCollectedNo",
          label: "No recolectados",
          class: "text-center",
          formatter: value => (value ? value : 0)
        },
        {
          key: "assignedNo",
          label: "Asignados",
          class: "text-center",
          formatter: value => (value ? value : 0)
        },
        { key: "status", label: "Estatus" },
        { key: "actions", label: "Acciones", class: "text-right" }
      ],
      serviceStatusOptions: [{ text: "Todos", value: null }].concat(
        ServiceStatus.options
      ),
      vehicleTypeOptions: [{ text: "Todos", value: null }].concat(
        DriverVehicles.options
      )
    };
  },

  computed: {
    perPage: {
      get() {
        return this.$store.getters[
          `${moduleName}/getCollectionsPaginationPerPage`
        ];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_COLLECTIONS_PAGINATION_PER_PAGE}`,
          val
        );
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[
          `${moduleName}/getCollectionsPaginationCurrentPage`
        ];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_COLLECTIONS_PAGINATION_CURRENT_PAGE}`,
          val
        );
      }
    },
    dateOrder: {
      get() {
        return this.$store.getters[
          `${moduleName}/getCollectionsFilterDateOrder`
        ];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_COLLECTIONS_FILTER_DATE_ORDER}`,
          val
        );
      }
    },
    totalRows() {
      return this.$store.getters[`${moduleName}/getCollectionsTotal`];
    },
    skip() {
      return this.perPage * (this.currentPage - 1);
    },
    filter() {
      return { skip: this.skip, limit: this.perPage };
    },
    collections() {
      return this.$store.getters[`${moduleName}/getCollections`];
    },
    searchText: {
      get() {
        return this.$store.getters[`${moduleName}/getCollectionsFilterText`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_COLLECTIONS_FILTER_TEXT}`,
          val
        );
      }
    },
    selectedCollectionStatus: {
      get() {
        return this.$store.getters[`${moduleName}/getCollectionsFilterStatus`];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_COLLECTIONS_FILTER_STATUS}`,
          val
        );
      }
    },
    selectedServiceId() {
      return this.selectedService?.id;
    },
    hasMessage: {
      get() {
        return this.isSuccessful || this.hasError;
      },
      set(val) {
        this.isSuccessful = val;
        this.hasError = val;
      }
    }
  },

  watch: {
    searchText() {
      this.search();
    },
    dateOrder: "getListing",
    currentPage: "getListing",
    perPage: "getCollectionsAndResetPagination",
    selectedCollectionStatus: "getCollectionsAndResetPagination"
  },

  created() {
    this.$store.commit(`${moduleName}/${mutation.CLEAR_COLLECTIONS_FILTERS}`);
    this.getListing();
    this.search = _debounce(() => {
      this.getListing();
    }, 600);
  },

  methods: {
    getListing() {
      this.loading = true;
      const { where, filter } = this.buildRequest();
      this.$store
        .dispatch(`${moduleName}/getCollectionServicesListing`, {
          where,
          filter,
          warehouseId: this.warehouseId
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildRequest() {
      const filter = {
        ...this.filter,
        order: `createdAt ${this.dateOrder}`
      };
      const where = {};
      if (this.searchText) where.like = this.searchText;

      if (this.selectedCollectionStatus != null)
        where.status = this.selectedCollectionStatus;

      return { where, filter };
    },
    shortId(val) {
      return makeShortUUID(val);
    },
    getCollectionsAndResetPagination() {
      this.paginationReset();
      this.getListing();
    },

    paginationReset() {
      this.currentPage = 1;
    },

    vehicle(type) {
      switch (type) {
        case VehicleType.NUM_MOTO:
          return "zubut-motorcycle";
        case VehicleType.NUM_CAR:
          return "zubut-car";
        case VehicleType.NUM_ECO:
          return "zubut-bike";
        default:
          return "zubut-motorcycle";
      }
    },

    successMessage(message) {
      this.message = message;
      this.isSuccessful = true;
      this.typeOfMessage = "success";
    },

    errorMessage(err, fallback) {
      this.message = err?.message || fallback;
      this.hasError = true;
      this.typeOfMessage = "error";
    },

    handleAssignation(res) {
      this.successMessage("Servicio asignado con éxito");
    },

    handleError(err) {
      this.errorMessage(
        err,
        "Ocurrió un error al intentar asignar el servicio"
      );
    },

    cancelService(service) {
      this.selectedService = service;
      this.showConfirmationModal = true;
    },

    finalizeService(service) {
      this.selectedService = service;
      this.showFinalizeConfirmationModal = true;
    },

    statusCheck(status) {
      return ServiceStatus.COMPLETED_STATUS.includes(status);
    },

    dispatchCancel() {
      this.showConfirmationModal = false;
      const service = this.selectedService;
      const cancel = {
        id: service.id,
        clientId: service.clientId,
        reason: 0
      };
      this.$store
        .dispatch(`${moduleName}/cancelService`, cancel)
        .then(res => {
          this.isSuccessful = true;
          this.message =
            res?.response?.message || "Servicio cancelado correctamente";
          this.typeOfMessage = "success";
        })
        .catch(err => {
          this.hasError = true;
          this.typeOfMessage = "error";
          this.message = err?.message || "Error al cancelar el servicio";
        })
        .finally(() => {
          this.getListing();
        });
    },

    dispatchFinalize() {
      this.showFinalizeConfirmationModal = false;
      const service = this.selectedService;
      this.$store
        .dispatch(`${moduleName}/finalizeService`, service.id)
        .then(res => {
          this.isSuccessful = true;
          this.message =
            res?.response?.message || "Servicio finalizado correctamente";
          this.typeOfMessage = "success";
        })
        .catch(err => {
          this.hasError = true;
          this.typeOfMessage = "error";
          this.message = err?.message || "Error al finalizar el servicio";
        })
        .finally(() => {
          this.getListing();
        });
    },

    handleRowClick(item) {
      this.$router.push({ name: "serviceDetail", params: { id: item.id } });
    },

    updateListing() {
      this.$emit("update-listing");
    }
  }
};
</script>

<style lang="scss" scoped>
@include media-breakpoint-up(sm) {
  .create-button {
    min-width: 130px;
  }
}
</style>
