<template>
  <div class="d-flex flex-column flex-md-row justify-content-md-between">
    <div class="d-flex mb-3 mb-md-0">
      <service-type-avatar class="mr-3" size="lg" content="G" color="#6655f6" />
      <div class="warehouse-info d-flex flex-column">
        <div id="title">ID {{ shortCityId }} {{ city.abbreviation }}</div>
        <p class="mb-0 mt-2">Ciudad: {{ city.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar.vue";

import { makeShortUUID } from "@zubut/common/src/utils/strings";

export default {
  name: "WarehouseCityHeader",
  components: { ServiceTypeAvatar },
  props: { cityId: { type: String, default: "" } },
  data() {
    return {
      city: null
    };
  },
  computed: {
    shortCityId() {
      return makeShortUUID(this.cityId);
    }
  },
  created() {
    const { name, state, abbreviation } = this.$route.query;
    this.city = { name, state, abbreviation };
  }
};
</script>

<style lang="scss" scope>
#title {
  color: $nero;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

p {
  font-size: 12px;
}
</style>
