











import Vue from "vue";
import { makeShortUUID } from "../utils/strings";
import ZShortenText from "./ZShortenText.vue";

export default Vue.extend({
  name: "ZCopyId",

  components: {
    ZShortenText
  },

  props: {
    text: {
      type: String,
      required: true
    },
    tooltipPlacement: {
      type: String,
      default: "top",
      validator: val => ["top", "bottom", "left", "right"].indexOf(val) > -1
    }
  },

  computed: {
    shortText(): string {
      return makeShortUUID(this.text);
    }
  }
});
