<template>
  <div>
    <go-back
      v-if="showGoBack"
      title="Detalle de bodega"
      class="mb-3"
      @back-action="$router.go(-1)"
    />
    <div class="d-flex flex-column flex-md-row justify-content-md-between">
      <div class="d-flex mb-3 mb-md-0">
        <service-type-avatar
          class="mr-3"
          size="lg"
          content="G"
          color="#6655f6"
        />
        <div class="warehouse-info d-flex flex-column">
          <div id="title">ID {{ shortWarehouseId }} {{ warehouseName }}</div>
          <p class="mb-0 mt-2">
            {{ warehouseAddress }}
          </p>
          <p v-if="warehousePhone" class="mt-2 mb-0">
            Tel: {{ warehousePhone }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row button-container">
        <div class="mr-md-3">
          <z-button-refresh
            class="refresh-btn"
            :loading="loadingListings"
            @refresh="updateListings"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBack from "@zubut/common/src/components/GoBackTitle";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";
import ZButtonRefresh from "@zubut/common/src/components/ZButtonRefresh";
import ScreensMixin from "@/mixins/screen";
import { makeShortUUID } from "@/utils/strings";
import Warehouses from "@/services/warehouses";

const moduleName = "serviceCenter/warehouses";

export default {
  name: "WarehouseDetailHeader",

  components: {
    GoBack,
    ServiceTypeAvatar,
    ZButtonRefresh
  },

  mixins: [ScreensMixin],

  props: {
    isOnlyZubutWarehouses: { type: Boolean, default: false },
    warehouseId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      loading: false,
      loadingPackages: false,
      loadingCollectionPackages: false,
      loadingDeliveryPackages: false,
      warehouse: null,
      showGoBack: false
    };
  },

  computed: {
    warehouseAddress() {
      return this.warehouse?.address || "";
    },
    warehouseName() {
      return this.warehouse?.name || "";
    },
    warehousePhone() {
      return this.warehouse?.phone || "";
    },
    shortWarehouseId() {
      return makeShortUUID(this.warehouseId);
    },
    loadingListings() {
      return (
        this.loadingPackages ||
        this.loadingCollectionPackages ||
        this.loadingDeliveryPackages
      );
    }
  },

  created() {
    this.showGoBack = this.isMobile();
  },

  mounted() {
    this.getWarehouse();
  },

  methods: {
    getWarehouse() {
      Warehouses.listing({
        where: {
          warehouseId: this.warehouseId,
          like: ""
        }
      })
        .then(res => {
          this.warehouse = res.data[0];
        })
        .catch(err => {
          this.$captureError(err);
        });
    },
    updateListings() {
      if (this.isOnlyZubutWarehouses) {
        this.getCollectionPackages();
      } else {
        this.getDeliveryPackages();
      }
    },
    getCollectionPackages() {
      this.loadingCollectionPackages = true;
      this.$store
        .dispatch(`${moduleName}/getCollectionServicesListing`, {
          warehouseId: this.warehouseId
        })
        .finally(() => (this.loadingCollectionPackages = false));
    },
    getDeliveryPackages() {
      this.loadingDeliveryPackages = true;
      this.$store
        .dispatch(`${moduleName}/getDeliveryServicesListing`, {
          warehouseId: this.warehouseId
        })
        .finally(() => (this.loadingDeliveryPackages = false));
    }
  }
};
</script>

<style lang="scss" scoped>
#title {
  color: $nero;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

.warehouse-info {
  p {
    font-size: 12px;
  }
}

.refresh-btn {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .refresh-btn {
    width: 122px;
  }
}
</style>
