<template>
  <z-modal
    :show="show"
    centered
    body-class="p-4 rounded parcel-detail-modal-body"
    dialog-class="parcel-detail-modal-dialog"
    hide-backdrop
    @hide="hide"
  >
    <parcel-detail :parcel-id="parcelId" />
  </z-modal>
</template>

<script>
import ParcelDetail from "./ParcelDetail";
import ZModal from "@zubut/common/src/components/ZModal";

export default {
  name: "ParcelDetailDesktop",

  components: {
    ParcelDetail,
    ZModal
  },

  props: {
    parcelId: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    hide() {
      this.$emit("update:show", false);
    }
  }
};
</script>

<style lang="scss">
.parcel-detail-modal-body {
  min-height: 94vh;
  padding: 1.5rem 1.5rem 3rem 1.5rem !important;
  box-shadow: -1px 0 8px 0 rgba(0, 0, 0, 0.2) !important;
}

.parcel-detail-modal-dialog {
  margin-left: 7.3rem;
  box-shadow: -1px 0 8px 0 rgba(0, 0, 0, 0.2) !important;
  max-width: 90.2% !important;
}
</style>
