<template>
  <div>
    <b-modal
      id="create-delivery-service-modal"
      size="md"
      centered
      header-class="modal-header"
      content-class="m-4 m-sm-0"
      body-class="p-5"
      hide-header
      hide-footer
      :show="show"
      :visible="show"
      @hidden="hideModal"
    >
      <div class="modal-container">
        <div class="mb-5">
          <div class="h4 font-weight-bold">
            Crear servicio de entrega para la bodega {{ shortWarehouseId }}
          </div>
          <div>Selecciona el tipo de vehículo</div>
        </div>
        <div
          class="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between"
        >
          <div
            class="z-modal-option mb-3 mb-md-0"
            @click="selectVehicleType(VehicleType.NUM_MOTORCYCLE)"
          >
            <div class="z-modal-icon">
              <font-awesome-icon icon="zubut-motorcycle" size="6x" />
            </div>
            <span>{{ VehicleType.MOTORCYCLE }}</span>
          </div>
          <div class="z-modal-option mb-3 mb-md-0">
            <div class="z-modal-icon">
              <font-awesome-icon
                icon="zubut-bike"
                size="6x"
                @click="selectVehicleType(VehicleType.NUM_ECO)"
              />
            </div>
            <span>{{ VehicleType.ECO }}</span>
          </div>
          <div class="z-modal-option">
            <div class="z-modal-icon">
              <font-awesome-icon
                icon="zubut-car"
                size="6x"
                @click="selectVehicleType(VehicleType.NUM_CAR)"
              />
            </div>
            <span>{{ VehicleType.CAR }}</span>
          </div>
        </div>
      </div>
    </b-modal>
    <confirmation-dialog
      close-on-cancel
      :loading="creatingService"
      :show.sync="showConfirmationModal"
      @accept="createDeliveryService"
    >
      ¿Deseas crear un servicio de entrega para la bodega
      <b>{{ shortWarehouseId }}</b> con un vehículo tipo
      <b>{{ selectedVehicleTypeName }}</b
      >?
    </confirmation-dialog>
  </div>
</template>

<script>
import ConfirmationDialog from "@/app/components/ConfirmationDialog";
import VehicleType from "@/constants/vehicles/type";
import notifyMixin from "@/mixins/notify";
import Warehouses from "@/services/warehouses";
import { makeShortUUID } from "@/utils/strings";

export default {
  name: "CreateDeliveryServiceModal",

  components: {
    ConfirmationDialog
  },

  mixins: [notifyMixin("notifications")],

  props: {
    warehouseId: {
      type: String,
      required: true
    },
    show: Boolean
  },

  data() {
    return {
      showConfirmationModal: false,
      selectedVehicleType: 1,
      VehicleType,
      creatingService: false
    };
  },

  computed: {
    shortWarehouseId() {
      return makeShortUUID(this.warehouseId);
    },
    selectedVehicleTypeName() {
      return VehicleType.get[this.selectedVehicleType];
    }
  },

  methods: {
    hideModal() {
      this.$emit("update:show", false);
      this.showConfirmationModal = false;
    },
    selectVehicleType(vehicleType) {
      this.selectedVehicleType = vehicleType;
      this.showConfirmationModal = true;
    },
    createDeliveryService() {
      this.creatingService = true;
      Warehouses.requestDeliveryService(this.warehouseId, {
        vehicles: [this.selectedVehicleType]
      })
        .then(() => {
          this.notify({
            type: "success",
            title: "Servicio creado exitosamente",
            text: `Servicio creado para bodega ${this.warehouseId}`
          });
          this.$emit("created");
        })
        .catch(err => {
          this.notify({
            type: "error",
            title: "Error al intentar crear el servicio",
            text: err?.message || ""
          });
          this.$captureError(err);
        })
        .finally(() => {
          this.creatingService = false;
          this.showConfirmationModal = false;
          this.$emit("update:show", false);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.z-modal-option {
  text-align: center;
  .z-modal-icon {
    cursor: pointer;
    border-radius: 2px;
    width: 8rem;
    height: 8rem;
    background-color: $solitude;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    &:hover {
      background-color: $gainsboro;
    }
  }
}
</style>
