<template>
  <z-modal
    :show="show"
    centered
    body-class="p-4 rounded"
    :hide-close="true"
    size="lg"
    @update:show="$emit('update:show', false)"
  >
    <div>
      <quick-message
        class="mb-2"
        with-icon
        :show.sync="hasMessage"
        :message="message"
        :type="typeOfMessage"
      />
      <div class="warehouse-info d-flex flex-column mb-4">
        <div id="subtitle">Servicio de recolección</div>
        <div id="title">Seleccionar paquetes</div>
      </div>
      <b-row class="mt-4">
        <b-col cols="12" md="6">
          <z-search-input
            v-model="searchText"
            placeholder="Busca por ID o External ID"
          />
        </b-col>
        <b-col cols="12" md="6">
          <z-dropdown-clients id="dropdown-clients" v-model="selectedClient" />
        </b-col>
      </b-row>
      <z-table
        class="mt-4"
        hover
        responsive="sm"
        :items="parcels"
        :fields="fields"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <loading-spinner />
          </div>
        </template>
        <template v-slot:empty>
          <p class="text-center py-5 my-5 font-weight-semi-bold">
            No hay paquetes registrados con este filtro
          </p>
        </template>
        <!-- Cells -->
        <template v-slot:cell(select)="data">
          <b-form-checkbox
            v-model="selectedParcels"
            :value="data.item.id"
            class="checkbox"
            size="lg"
          />
        </template>
      </z-table>
      <z-table-pagination
        :total-rows="totalRows"
        :per-page.sync="perPage"
        :current-page.sync="currentPage"
      />
      <z-button
        :disabled="!selectedParcels.length || requesting"
        :loading="requesting"
        class="mt-3"
        @click="request"
      >
        Añadir servicio
      </z-button>
    </div>
  </z-modal>
</template>

<script>
import ZDropdownClients from "@/app/components/ZDropdownClients";
import ZModal from "@zubut/common/src/components/ZModal";
import ZSearchInput from "@zubut/common/src/components/ZSearchInput";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import pagination from "@/mixins/pagination";
import Parcels from "@/services/parcels";
import Warehouses from "@/services/warehouses";
import { makeShortUUID } from "@/utils/strings";
import _debounce from "lodash/debounce";

export default {
  name: "CreateCollection",

  components: {
    ZDropdownClients,
    ZModal,
    ZSearchInput,
    ZTable,
    ZTablePagination
  },

  mixins: [pagination],

  props: {
    show: {
      type: Boolean,
      default: false
    },
    warehouseId: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      fields: [
        { key: "select", label: "" },
        { key: "id", label: "ID", formatter: val => makeShortUUID(val) },
        {
          key: "externalId",
          label: "ID Externo",
          class: "text-center"
        },
        { key: "client", label: "Cliente" },
        {
          key: "destinationName",
          label: "Destino"
        }
      ],
      searchText: "",
      selectedClient: null,
      loading: false,
      parcels: [],
      selectedParcels: [],
      perPage: 5,
      requesting: false,
      message: "",
      typeOfMessage: "info",
      isSuccessful: false,
      hasError: false
    };
  },

  computed: {
    hasMessage: {
      get() {
        return this.isSuccessful || this.hasError;
      },
      set(val) {
        this.isSuccessful = val;
        this.hasError = val;
      }
    }
  },

  watch: {
    selectedClient: "getParcelsAndResetPagination",
    currentPage: "getParcels",
    perPage: "getParcelsAndResetPagination",
    searchText() {
      this.search();
    },
    show() {
      this.getParcelsAndResetPagination();
      this.reset();
    }
  },

  created() {
    this.search = _debounce(() => {
      this.getParcels();
    }, 600);
  },

  methods: {
    getParcels() {
      this.loading = true;
      const where = this.buildWhere();
      const filter = this.filter;
      Parcels.collectable({ filter, where })
        .then(({ data, meta }) => {
          this.parcels = data;
          if (meta.skip === 0) {
            this.totalRows = meta.count;
          }
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    buildWhere() {
      const where = {
        warehouseId: this.warehouseId
      };

      if (this.searchText) {
        where.like = this.searchText;
      }

      if (this.selectedClient) {
        where.clientId = this.selectedClient;
      }

      return where;
    },
    getParcelsAndResetPagination() {
      this.paginationReset();
      this.getParcels();
    },
    paginationReset() {
      this.currentPage = 1;
    },
    request() {
      this.requesting = true;
      Warehouses.requestCollectionService(
        this.warehouseId,
        this.selectedParcels
      )
        .then(res => {
          this.isSuccessful = true;
          this.message =
            res?.response?.message || "Servicio creado correctamente";
          this.typeOfMessage = "success";
          this.$emit("created-service");
        })
        .catch(err => {
          this.hasError = true;
          this.typeOfMessage = "error";
          this.message = err?.message || "Error al creal el servicio";
        })
        .finally(() => {
          this.requesting = false;
          this.selectedParcels.splice(0);
          this.getParcelsAndResetPagination();
        });
    },
    reset() {
      this.message = "";
      this.typeOfMessage = "info";
      this.isSuccessful = false;
      this.hasError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#title {
  color: $nero;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
}

#subtitle {
  color: $dim-gray;
  font-size: 12px;
  font-weight: 600;
}
</style>
