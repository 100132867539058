<template>
  <div>
    <z-modal :show.sync="showModal">
      <warehouse-detail-header
        :warehouse-id="warehouseId"
        :is-only-zubut-warehouses="isOnlyZubutWarehouses"
      />
      <warehouse-detail-collections
        v-if="isOnlyZubutWarehouses"
        :warehouse-id="warehouseId"
        @update-listing="updateListing"
      />
      <warehouse-detail-deliveries
        v-else
        :warehouse-id="warehouseId"
        @update-listing="updateListing"
      />
    </z-modal>
    <b-row v-if="!isOnlyZubutWarehouses" class="mt-4">
      <b-col lg="2">
        <z-dropdown-check-list-clients v-model="selectedClientId" />
      </b-col>
    </b-row>
    <z-table
      class="mt-4"
      responsive="sm"
      :items="warehouses"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :loading="loading"
      style="min-height:330px"
      @row-clicked="handleRowClick"
      @filtered="onFiltered"
    >
      <template #empty>
        <p class="text-center py-5 my-5 font-weight-semi-bold">
          No hay bodegas registradas que mostrar
        </p>
      </template>
      <template #emptyfiltered>
        <p class="text-center py-5 my-5 font-weight-semi-bold">
          No hay bodegas registradas con ese filtro
        </p>
      </template>

      <template v-slot:cell(id)="data">
        <z-copy-id tooltip-placement="right" :text="data.unformatted" />
      </template>
    </z-table>
    <z-table-pagination
      :total-rows="rows"
      :per-page.sync="perPage"
      :current-page.sync="currentPage"
    />
  </div>
</template>

<script>
import WarehouseDetailCollections from "./WarehouseDetailCollections.vue";
import WarehouseDetailDeliveries from "./WarehouseDetailDeliveries.vue";
import WarehouseDetailHeader from "./WarehouseDetailHeader.vue";
import ZCopyId from "@zubut/common/src/components/ZCopyId.vue";
import ZDropdownCheckListClients from "@/app/components/ZDropdownCheckListClients.vue";
import ZModal from "@zubut/common/src/components/ZModal.vue";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination.vue";

import Warehouses from "@/services/warehouses";
import * as mutation from "@/store/modules/serviceCenter/warehouses/mutations-types";
import { makeShortUUID } from "@zubut/common/src/utils/strings";

const moduleName = "serviceCenter/warehouses";

export default {
  name: "WarehousesList",
  components: {
    WarehouseDetailCollections,
    WarehouseDetailDeliveries,
    WarehouseDetailHeader,
    ZCopyId,
    ZDropdownCheckListClients,
    ZModal,
    ZTable,
    ZTablePagination
  },

  props: {
    isOnlyZubutWarehouses: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      showModal: false,
      warehouseId: null,
      searchText: "",
      warehouses: [],
      currentPage: 1,
      perPage: 10,
      rows: 1,

      fields: [
        {
          key: "id",
          label: "ID",
          formatter: val => makeShortUUID(val),
          thClass: "thStyle"
        },
        {
          key: "name",
          label: "Nombre",
          thClass: "thStyle"
        },
        { key: "address", label: "Dirección" },
        { key: "phone", label: "Teléfono" }
      ]
    };
  },
  computed: {
    selectedClientId: {
      get() {
        return this.$store.getters[
          `${moduleName}/getWarehousesFilteredByClientId`
        ];
      },
      set(val) {
        this.$store.commit(
          `${moduleName}/${mutation.UPDATE_WAREHOUSES_FILTER_CLIENT}`,
          val
        );
      }
    }
  },

  watch: {
    selectedClientId: "fetchWarehouses"
  },

  mounted() {
    this.fetchWarehouses();
  },

  methods: {
    fetchWarehouses() {
      this.loading = true;
      const cityId = this.$route.params.id;

      const where = {
        cityId,
        onlyZubut: this.isOnlyZubutWarehouses
      };

      if (this.selectedClientId.length > 0) {
        where.clientId = { inq: this.selectedClientId };
      }

      Warehouses.listingByCity(null, where)
        .then(warehouses => {
          this.warehouses = warehouses.data;
          this.rows = warehouses.meta.length;
        })
        .catch(err => {
          this.$captureError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    handleRowClick(item) {
      this.showModal = true;
      this.warehouseId = item.id;
    },

    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    updateListing() {
      this.$emit("update-listing");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .thStyle {
  width: 15%;
}
</style>
