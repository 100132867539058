





























import Vue, { PropType } from "vue";
import ZDropdownCheckList from "@zubut/common/src/components/ZDropdownCheckList.vue";
import ClientTypes from "@zubut/common/src/constants/clients/type";
import DropdownOption from "@zubut/common/src/models/dropdown-option";
import _debounce from "lodash/debounce";
import * as mutation from "@/store/modules/clients/mutations-types";

import Clients from "@/services/clients";

interface Where {
  type: Number | unknown[];
  cityId?: String | null;
  id?: String[];
  like?: String;
}
interface Filter {
  skip: number;
  order: string;
  limit?: number;
}

export default Vue.extend({
  name: "ZDropdownCheckListClients",
  components: {
    ZDropdownCheckList
  },
  props: {
    clientTypes: {
      type: [Array, Number],
      default: () => [
        ClientTypes.NUM_CLIENT,
        ClientTypes.NUM_COMPANY,
        ClientTypes.NUM_BRANCH
      ]
    },
    maxHeight: {
      type: String,
      default: "400px"
    },
    value: { type: Array as PropType<String[]>, required: true }
  },
  data() {
    return {
      checked: [] as String[],
      clientOptions: [] as DropdownOption[],
      name: "Cliente",
      searchClient: null as any,
      selectAllClients: true,
      text: "" as string
    };
  },
  computed: {
    disableButton(): boolean {
      return this.selectAllClients === false && this.checked.length === 0
        ? true
        : false;
    },
    dropdownNameText(): string {
      return this.disableButton === true
        ? `${this.name}: Ninguno`
        : this.selectAllClients === true
        ? `${this.name}: Todos`
        : `${this.name}: Múltiple`;
    },
    isFilterOptionsEmpty(): boolean {
      return (
        this.$store.getters["clients/clientsCheckboxFilterOptions"].length === 0
      );
    },
    selectedCity(): string | null {
      return this.$store.getters["cities/getCity"];
    }
  },
  watch: {
    checked() {
      this.selectAllClients = this.checked.length === 0 ? true : false;

      const isCheckboxClicked = true;
      this.getClientsOptions(this.text, isCheckboxClicked);
    },
    selectedCity() {
      this.getClientsOptions();
      this.checked = [];
    },
    selectAllClients() {
      if (this.selectAllClients === true) {
        this.checked = [];
      }
    }
  },
  created() {
    this.searchClient = _debounce(searchText => {
      this.getClientsOptions(searchText);
    }, 600);

    this.getClientsOptions();
  },
  methods: {
    assignClientOptionsFromStore() {
      this.clientOptions = this.$store.getters[
        "clients/clientsCheckboxFilterOptions"
      ];
    },
    getClientsOptions(searchText = "", isCheckboxClicked = false) {
      let where: Where = {
        like: searchText,
        type: this.clientTypes
      };
      let filter: Filter = { skip: 0, limit: 5, order: "lastLogin DESC" };

      if (this.selectedCity) {
        where.cityId = this.selectedCity;
      }

      searchText = searchText ? searchText.toLowerCase() : searchText;

      if (isCheckboxClicked) {
        where.id = this.checked;
        delete where.like;

        delete filter.limit;
      }

      if (!this.isFilterOptionsEmpty && searchText === "") {
        this.assignClientOptionsFromStore();
        this.text = searchText;
      } else {
        this.fetchClients(where, filter, isCheckboxClicked);
        this.text = searchText;
      }
    },
    fetchClients(where: Where, filter: Filter, isCheckboxClicked: Boolean) {
      Clients.nameListing({
        filter,
        where
      })
        .then(res => {
          const formattedOptions = res.data.map((user: any) => ({
            value: user.id,
            text: user.name
          }));

          if (isCheckboxClicked === false) {
            this.clientOptions = formattedOptions;
          }

          if (this.isFilterOptionsEmpty || isCheckboxClicked) {
            this.$store.commit(
              `clients/${mutation.SET_CLIENTS_CHECKBOX_FILTER}`,
              formattedOptions
            );
          }
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {});
    },

    setCustomColumns(columns: any) {
      this.$emit("input", columns);
      (this.$refs.dropdown as any).hide(true);
    }
  }
});
