<template>
  <z-modal
    :show="show"
    centered
    body-class="p-4 rounded"
    :hide-close="true"
    size="md"
  >
    <quick-message
      class="mb-2"
      with-icon
      :show.sync="hasMessage"
      :message="message"
      :type="typeOfMessage"
    />
    <div class="font-weight-bold">
      Selecciona el numero de mensajeros
    </div>
    <z-input v-model="driversNo" class="mt-4" type="number" min="1"></z-input>
    <div class="text-right">
      <z-button
        class="mt-4"
        :loading="loading"
        :disabled="disabled"
        @click="handleGenerateClusters"
        >Generar</z-button
      >
    </div>
  </z-modal>
</template>

<script>
import Warehouses from "@/services/warehouses";
import ZModal from "@zubut/common/src/components/ZModal";

export default {
  name: "CreateClustersModal",

  components: {
    ZModal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    warehouseId: {
      type: String,
      default: ""
    },
    isCollection: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasMessage: null,
      message: null,
      typeOfMessage: "success",
      driversNo: 1,
      loading: false,
      disabled: false
    };
  },

  methods: {
    handleGenerateClusters() {
      this.loading = true;
      this.disabled = true;
      if (this.isCollection) {
        Warehouses.collectionRoutes(
          this.warehouseId,
          parseInt(this.driversNo, 10)
        )
          .then(res => {
            this.hasMessage = true;
            this.message =
              res?.response?.message || "Servicio creado correctamente";
            this.typeOfMessage = "success";
            this.$emit("created-service");
          })
          .catch(err => {
            this.hasMessage = true;
            this.hasError = true;
            this.typeOfMessage = "error";
            this.message = err?.message || "Error al creal el servicio";
          })
          .finally(() => {
            this.loading = false;
            this.$emit("update-listing");
            setTimeout(() => {
              this.disabled = false;
              this.$emit("update:show", false);
            }, 2000);
          });
      } else {
        Warehouses.deliveryRoutes(
          this.warehouseId,
          parseInt(this.driversNo, 10)
        )
          .then(res => {
            this.hasMessage = true;
            this.message =
              res?.response?.message || "Servicio creado correctamente";
            this.typeOfMessage = "success";
            this.$emit("created-service");
          })
          .catch(err => {
            this.hasMessage = true;
            this.hasError = true;
            this.typeOfMessage = "error";
            this.message = err?.message || "Error al creal el servicio";
          })
          .finally(() => {
            this.loading = false;
            this.$emit("update-listing");
            setTimeout(() => {
              this.disabled = false;
              this.$emit("update:show", false);
            }, 2000);
          });
      }
    }
  }
};
</script>
